import dayjs from "dayjs"
import DOMPurify from "dompurify"
import Fuse from "fuse.js"
import camelCase from "lodash/camelCase"
import mapKeys from "lodash/mapKeys"
import Geocode from "react-geocode"
import { createClient } from "urql"

export const OPACITY_75 = "BF"
export const OPACITY_25 = "40"
export const OPACITY_50 = "80"
export const OPACITY_40 = "66"

export function timeAgo(timestamp) {
  const time = new Date(timestamp).getTime()
  const now = new Date().getTime()
  const difference = Math.abs(now - time)

  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(weeks / 4.345) // Average weeks in a month
  const years = Math.floor(months / 12)

  if (minutes < 60) {
    return minutes + " min ago"
  } else if (hours < 24) {
    return hours + " hour" + (hours !== 1 ? "s" : "") + " ago"
  } else if (days < 7) {
    return days + " day" + (days !== 1 ? "s" : "") + " ago"
  } else if (weeks < 4.345) {
    return weeks + " week" + (weeks !== 1 ? "s" : "") + " ago"
  } else if (months < 12) {
    return months + " month" + (months !== 1 ? "s" : "") + " ago"
  } else {
    return years + " year" + (years !== 1 ? "s" : "") + " ago"
  }
}

export function distanceInMiles(lat1, lon1, lat2, lon2) {
  const radiusOfEarthInMiles = 3958.8
  // Convert degrees to radians
  const rlat1 = lat1 * (Math.PI / 180)
  const rlat2 = lat2 * (Math.PI / 180)
  const difflat = rlat2 - rlat1 // Difference in latitude in radians
  const difflon = (lon2 - lon1) * (Math.PI / 180) // Difference in longitude in radians

  // Haversine formula
  return (
    2 *
    radiusOfEarthInMiles *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    )
  )
}

export const updateLocationSearchText = (latLng) => {
  Geocode.fromLatLng(latLng[0], latLng[1], process.env.GOOGLE_MAPS_API_KEY)
    .then((response) => {
      let results = response.results

      let result = results[0]

      let cityStateCountry = getCityFromGeoResult(result)

      const event = new CustomEvent("newLocationChosen", { detail: stripUSAFromString(cityStateCountry) })
      document.dispatchEvent(event)
    })
    .catch((error) => {
      console.error(error)
    })
}

export function getCityFromGeoResult(result) {
  const { address_components } = result
  let city = ""
  let state = ""
  let country = ""

  const componentTypes = {
    CITY: ["locality", "postal_town", "administrative_area_level_2"],
    STATE: ["administrative_area_level_1"],
    COUNTRY: ["country"]
  }

  address_components.forEach((component) => {
    if (!city && componentTypes.CITY.some((type) => component.types.includes(type))) {
      city = component.long_name
    }
    if (!state && componentTypes.STATE.some((type) => component.types.includes(type))) {
      state = component.short_name || component.long_name
    }
    if (!country && componentTypes.COUNTRY.some((type) => component.types.includes(type))) {
      country = component.short_name === "US" ? "USA" : component.long_name
    }
  })

  return formatLocation(city, state, country)
}

function formatLocation(city, state, country) {
  if (city && state && country) {
    return `${city}, ${state}, ${country}`
  } else if (state && country) {
    return `${state}, ${country}`
  } else if (country) {
    return country
  }
  return ""
}

export function splitString({ string, numberWords }) {
  if (string.length > 0) {
    const stringArray = string.split(",")
    if (stringArray.length > 0) {
      if (stringArray[1] === "United States of America") {
        return stringArray[0]
      } else if (stringArray.slice(0, numberWords).join().length > 18) {
        return stringArray.slice(0, numberWords).join().substring(0, 18)
      } else {
        return stringArray.slice(0, numberWords).join()
      }
    }
  }
  return ""
}

export function stripUSAFromString(string) {
  return string.endsWith(", USA") ? string.replace(", USA", "") : string
}

export function formatPhone(phone) {
  const cleaned = ("" + phone).replace(/\D/g, "")
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
}

export function formatClientPhonePlusOne(phone) {
  if (!phone) return null
  let clientPhone = formatPhone(phone)
  if (clientPhone) {
    clientPhone = clientPhone.startsWith("+1") || clientPhone.startsWith("1") ? clientPhone : "+1 " + clientPhone
  }
  return clientPhone
}

export function clientName(client) {
  let clientName = client.firstName
  if (client.lastName) {
    clientName += ` ${client.lastName}`
  }
  if (clientName?.length === 0) {
    clientName = client.email || client.phone
  }
  return clientName
}

export function isClientValid(clientInput, clientPhone, practicePhone, practiceEmail) {
  const { firstName, lastName, phone, email } = clientInput
  return (
    firstName?.trim() !== "" &&
    lastName?.trim() !== "" &&
    phone?.trim() !== "" &&
    (clientPhone !== practicePhone || !practicePhone) &&
    email?.trim() !== practiceEmail
  )
}

export function formatPrice(price) {
  return (Math.abs(price) / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })
}

export function unformatPrice(string) {
  return parseFloat(string.replace(/[^0-9-.]/g, "")) * 100
}

export function stringPriceToInteger(price) {
  return Math.round(parseFloat(price.replace(/[^0-9-.]/g, "")) * 100)
}

export function formatDate(date) {
  return dayjs(date).format("MMMM Do YYYY [at] h:mm a")
}

export function formatShortDate(date) {
  return dayjs(date).format("MM/DD/YYYY")
}

export function formatShortDateTime(date) {
  return dayjs(date).format("MM/DD/YYYY [at] h:mm a")
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function appointmentStatusString(appointment) {
  if (appointment.newClient) {
    return "New client"
  } else if (appointment.state === "approved" && new Date(appointment.startsAt) < Date.now()) {
    return "Appointment completed"
  } else if (appointment.state === "cancelled") {
    return "Appointment cancelled"
  }
}

export function findBestMatchService(hit, query, isVirtual) {
  if (!hit.services) return null

  const servicesWithVariations = [].concat.apply(
    [],
    hit.services.map((service) => {
      if (service.variations) {
        return service.variations.map((variation) => ({ ...service, ...variation }))
      } else {
        return [service]
      }
    })
  )

  const fuse = new Fuse(servicesWithVariations.map((service) => service.name))
  const matches = fuse.search(query).map((match) => servicesWithVariations[match.refIndex])
  const services = query ? matches : servicesWithVariations

  const freeService = servicesWithVariations.find((service) => service.amount_cents === 0)

  const cheapestMatchingServiceAmount = Math.min(
    ...services.filter((service) => service.amount_cents > 0).map((service) => service.amount_cents)
  )
  const cheapestMatchingService = services.filter(
    (service) =>
      (isVirtual ? service.is_virtual === isVirtual : true) && service.amount_cents === cheapestMatchingServiceAmount
  )[0]

  const cheapestServiceAmount = Math.min(
    ...servicesWithVariations.filter((service) => service.amount_cents > 0).map((service) => service.amount_cents)
  )
  const cheapestService = servicesWithVariations.filter((service) => service.amount_cents === cheapestServiceAmount)[0]

  return cheapestMatchingService || cheapestService || freeService
}

export const truncate = (text, maxLength) => {
  if (text.length > maxLength) {
    let truncatedText = text.substring(0, maxLength - 3).trim()
    truncatedText = truncatedText.replace(/[.\s]+$/, "")

    return truncatedText + "..."
  }
  return text
}

export function findPhotoUrl(practitioner, service) {
  if (service.photoUrl.includes("defaults") && service.parent && !service.parent.photoUrl.includes("defualts")) {
    service.photoUrl = service.parent.photoUrl
    service.photos = service.parent.photos
  }
  let photoUrl = service.photos.large ? service.photos.large.jpeg : null
  if (!photoUrl) {
    photoUrl = service.photos.medium ? service.photos.medium.jpeg : null
  }
  if (!photoUrl) {
    photoUrl = practitioner?.filestack_photo?.large ? practitioner?.filestack_photo?.large?.jpeg : null
  }
  if (!photoUrl) {
    photoUrl = practitioner?.filestack_photo?.medium ? practitioner?.filestack_photo?.medium?.jpeg : null
  }
  return photoUrl
}

export function findAvailability(practice) {
  return practice.bookingAvailability?.availableSlots.filter((slot) => slot.datetimes.length > 0)
}

export function selectStyling() {
  const options = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),

    option: (provided, state) => ({
      ...provided,
      borderBottom: "transparent",
      padding: 16,
      backgroundColor: state.isSelected || state.isFocused ? "rgb(var(--color-ultra-light-gray))" : "#fff",
      color: "rgb(var(--color-black))"
    }),
    valueContainer: (provided) => ({ ...provided, padding: "0.375rem 0.75rem" }),
    dropdownIndicator: (provided) => ({ ...provided, color: "rgb(var(--color-dark-gray))" }),
    placeholder: (provided) => ({ ...provided, color: "rgb(var(--color-dark-gray))" }),
    input: (provided) => ({
      ...provided,
      color: "rgb(var(--color-dark-gray))",
      fontSize: "16px",
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0
    }),
    indicatorSeparator: () => ({ display: "none" }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.5rem",
      boxShadow: state.isSelected || state.isFocused ? "0 0 0 1px rgb(var(--color-blue))" : "none",
      borderColor: "rgb(var(--color-gray))",
      "&:hover": {
        borderColor: "rgb(var(--color-black))"
      },
      "&:focus": {
        boxShadow: "0 0 0 3px rgb(var(--color-blue))",
        borderColor: "rgb(var(--color-gray))"
      }
    })
  }

  return options
}

export function getDisplayAndFullTimeZone(location) {
  let timeZoneDisplay = ""
  let timeZone = ""
  if (location?.kind === "virtual") {
    timeZoneDisplay = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
    timeZone = new Date().toLocaleTimeString("en-us", { timeZoneName: "long" }).split(" ").slice(2).join(" ")
  } else {
    timeZoneDisplay = location?.timeZoneAbbr
    timeZone = location?.formattedTimeZone
  }

  return [timeZoneDisplay, timeZone]
}

export function getDisplayServiceRanges(service) {
  let amountCentsRange = `${service.amountCents > 0 ? `$${service.amountCents / 100}` : "Free"}`
  let timeLengthRange = `${service.timeLength}`
  if (service.variations.length > 1) {
    const lastService = service.variations[service.variations.length - 1]
    if (lastService.amountCents !== service.amountCents) {
      amountCentsRange += ` - ${lastService.amountCents > 0 ? `$${lastService.amountCents / 100}` : "Free"}`
    }
    if (lastService.timeLength !== service.timeLength) {
      timeLengthRange += ` - ${lastService.timeLength}`
    }
  }
  timeLengthRange += " min"
  return [amountCentsRange, timeLengthRange]
}

export function validEmail(email) {
  return email.match(
    /^(([^<>()[\].,;:@"]+(\.[^<>()[\].,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const sanitize = (value) => (typeof window !== "undefined" ? DOMPurify.sanitize(value) : value) // not sure why, but DOMPurify.sanitize is undefined in SSR

const urqlHeaders =
  typeof window !== "undefined"
    ? { "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content") }
    : {}

export const urqlClient = createClient({
  url: "/graphql",
  fetchOptions: {
    headers: urqlHeaders
  }
})

export const createUrqlClient = (options) =>
  createClient({
    url: "/graphql",
    fetchOptions: {
      headers: urqlHeaders
    },
    ...options
  })

export const networkOnlyUrqlClient = createUrqlClient({ requestPolicy: "network-only" })

export const formatAvailableTimes = (availableTimes) => {
  availableTimes = JSON.parse(availableTimes)
  availableTimes = availableTimes.reduce((acc, day) => {
    const dayName = day[0]
    const startAndEndTimes = day[1]
    acc[dayName] = mapKeys(startAndEndTimes, (value, key) => camelCase(key))
    return acc
  }, {})
  return availableTimes
}

export const ORDERED_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const copyToClipboard = async (
  text,
  showToast,
  successMessage = "Link copied!",
  errorMessage = "Failed to copy"
) => {
  try {
    await navigator.clipboard.writeText(text)
    showToast && showToast(successMessage)
  } catch (err) {
    showToast && showToast({ type: "error", content: errorMessage })
  }
}

export const commIntervals = [
  { value: 5, label: "5 mins" },
  { value: 15, label: "15 mins" },
  { value: 30, label: "30 mins" },
  { value: 60, label: "1 hrs" },
  { value: 120, label: "2 hrs" },
  { value: 240, label: "4 hrs" },
  { value: 480, label: "8 hrs" },
  { value: 1440, label: "24 hrs" },
  { value: 2880, label: "2 days" }
]

export const joinWithAnd = (array) => {
  if (array.length === 0) {
    return null
  } else if (array.length === 1) {
    return array[0]
  } else if (array.length === 2) {
    return `${array[0]} and ${array[1]}`
  } else {
    return `${array.slice(0, -1).join(", ")}, and ${array[array.length - 1]}`
  }
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16)
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255
  }
}

function rgbToHex({ r, g, b }) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

export function blendColors(topColor, topOpacity, bottomColor) {
  const topRgb = hexToRgb(topColor)
  const bottomRgb = hexToRgb(bottomColor)

  const blendedRgb = {
    r: Math.round(topRgb.r * topOpacity + bottomRgb.r * (1 - topOpacity)),
    g: Math.round(topRgb.g * topOpacity + bottomRgb.g * (1 - topOpacity)),
    b: Math.round(topRgb.b * topOpacity + bottomRgb.b * (1 - topOpacity))
  }

  return rgbToHex(blendedRgb)
}

export function lightenHexColor(hex, percent) {
  if (!hex) return hex

  hex = hex.replace(/^#/, "")

  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  r = Math.min(255, Math.floor(r * (1 + percent / 100)))
  g = Math.min(255, Math.floor(g * (1 + percent / 100)))
  b = Math.min(255, Math.floor(b * (1 + percent / 100)))

  r = r.toString(16).padStart(2, "0")
  g = g.toString(16).padStart(2, "0")
  b = b.toString(16).padStart(2, "0")

  return `#${r}${g}${b}`
}

export const isValidUrl = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}

export const stripHTMLTags = (htmlString) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")
  const textWithSpaces = []

  function traverse(node) {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        textWithSpaces.push(child.textContent.trim())
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        traverse(child)
        textWithSpaces.push(" ") // Add a space after each element node
      }
    })
  }

  traverse(doc.body)

  return textWithSpaces.join(" ").replace(/\s+/g, " ").trim() // Clean up extra spaces
}
