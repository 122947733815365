import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { createPortal } from "react-dom"
import { twMerge } from "tailwind-merge"

import { Button, CloseButton } from "./Buttons"
import Typography from "./Typography"

export const Box = ({ children, className, as = "div", keepBorderInMobile = false, ...rest }) => {
  const Tag = as

  return (
    <Tag
      {...rest}
      className={twMerge(
        "rounded-xl border border-gray-light bg-white p-8 md:p-6",
        keepBorderInMobile ? "" : "sm:border-none sm:p-0",
        className
      )}>
      {children}
    </Tag>
  )
}

export const EmptyStateBox = ({ children, className, ...rest }) => (
  <div
    {...rest}
    className={twMerge(
      "flex h-96 flex-col items-center justify-center rounded-xl bg-gray-ultralight p-6 text-center",
      className
    )}>
    <div className="max-w-[260px]">{children}</div>
  </div>
)

export const ProfilePageEmptyStateBox = ({
  children,
  title,
  className,
  onButtonClick,
  buttonCopy = "Add now",
  ...rest
}) => (
  <EmptyStateBox {...rest} className={twMerge("h-[438px]", className)}>
    <Typography variant="h4" as="h3">
      {title}
    </Typography>
    <Typography variant="subtitle">{children}</Typography>
    {onButtonClick && (
      <Button className="mt-4" onClick={onButtonClick}>
        {buttonCopy}
      </Button>
    )}
  </EmptyStateBox>
)

export const Banner = ({ children, className, type, ...rest }) => (
  <div
    {...rest}
    className={twMerge(
      "rounded-xl border border-gray-light bg-white p-4",
      type === "error"
        ? "border-red bg-red-light"
        : type === "warning"
        ? "border-orange bg-orange-light"
        : type === "info"
        ? "border-gray bg-gray-light"
        : type === "success"
        ? "border-green bg-green-light"
        : "",
      className
    )}>
    {children}
  </div>
)

Banner.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "info", "success"])
}

export const Divider = ({ className }) => <div className={twMerge("my-6 h-[1px] w-full bg-gray-light", className)} />

export const Flyout = ({ visible, closeFlyout, header, children, footer, footerSticky = true }) => {
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeFlyout()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  const sharedClasses = twMerge(
    `fixed w-[496px] bg-white duration-500 md:w-screen ${
      visible ? "right-0 md:right-0" : "right-[-496px] md:-right-100vw"
    }`
  )

  return (
    <>
      {createPortal(
        <>
          <div
            onClick={closeFlyout}
            className={`fixed inset-0 z-50 bg-black/50 transition-opacity duration-500 ${
              visible ? "visible" : "hidden"
            }`}
          />
          <div
            className={`Flyout top-0 z-[51] h-screen overflow-x-hidden overflow-y-scroll ${sharedClasses}`}
            style={{ overscrollBehaviorBlock: "contain" }}>
            <div
              className={`top-0 z-1 flex items-center justify-between border-b border-gray-light px-8 py-6 md:p-6 ${sharedClasses}`}>
              <Typography variant="h4" as="h2">
                {header}
              </Typography>
              <CloseButton onClick={closeFlyout} />
            </div>
            <div
              className={twMerge(
                "p-8 pt-[110px] md:p-6 md:pt-[100px]",
                footerSticky ? "pb-[112px] md:pb-[104px]" : ""
              )}>
              {children}
            </div>
            {footer && (
              <div className={`z-1 border-t border-gray-light p-6 ${footerSticky ? `${sharedClasses} bottom-0` : ""}`}>
                {footer}
              </div>
            )}
          </div>
        </>,
        document.body
      )}
    </>
  )
}
