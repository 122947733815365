import React from "react"
import { twMerge } from "tailwind-merge"

export const UnderlinedLink = ({ children, className, ...rest }) => (
  <a {...rest} className={twMerge("cursor-pointer font-bold text-teal underline", className)}>
    {children}
  </a>
)

export const Link = ({ children, className, ...rest }) => (
  <a {...rest} className={twMerge("cursor-pointer text-teal hover:underline", className)}>
    {children}
  </a>
)

export const TabNavLink = ({ children, className, active, ...rest }) => {
  const activeClass = active ? "text-black font-bold" : ""
  return (
    <a {...rest} className={twMerge("inline-flex w-fit flex-col gap-2 text-teal", activeClass, className)}>
      <span>{children}</span>
      {active && <div className="h-0.5 w-full bg-black" />}
    </a>
  )
}

export const ButtonLink = ({ children, className, ...rest }) => (
  <a
    {...rest}
    className={twMerge(
      "block w-fit rounded bg-teal px-4 py-1.5 font-bold text-white focus:outline-none focus:ring-2 focus:ring-blue",
      className
    )}>
    {children}
  </a>
)
