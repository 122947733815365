import PropTypes from "prop-types"
import React from "react"
import { twMerge } from "tailwind-merge"

const tags = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  hxl: "h1",
  body: "div",
  small: "span",
  micro: "span",
  subtitle: "div",
  smSubtitle: "div",
  title: "div",
  capitalHeading: "h2"
}

const classNames = {
  h1: "font-bold leading-tight text-[40px] sm:text-[32px]",
  h2: "font-bold leading-tight text-[32px] sm:text-[24px]",
  h3: "font-bold !leading-tight text-[24px] sm:text-[20px]",
  h4: "font-bold leading-tight text-[20px] sm:text-[18px]",
  h5: "font-bold leading-tight text-[18px] sm:text-[16px] sm:leading-normal",
  hxl: "font-bold leading-tight text-[64px] sm:text-[32px]",
  body: "text-base leading-normal",
  small: "text-sm",
  micro: "text-[13px] text-gray-dark",
  subtitle: "text-gray-dark",
  smSubtitle: "text-gray-dark text-sm",
  title: "font-bold mb-1",
  capitalHeading: "font-bold text-gray-dark text-sm uppercase tracking-wider"
}

const Typography = ({ variant = "body", children, className, as }) => {
  const sizeClassNames = classNames[variant]
  const Tag = as || tags[variant]

  return <Tag className={twMerge(sizeClassNames, className)}>{children}</Tag>
}

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.keys(classNames)).isRequired
}

export default Typography
