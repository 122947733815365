import dayjs from "dayjs"

const advancedFormat = require("dayjs/plugin/advancedFormat")
const relativeTime = require("dayjs/plugin/relativeTime")
const timezone = require("dayjs/plugin/timezone")
const utc = require("dayjs/plugin/utc")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
